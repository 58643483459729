html {
  background: url("../images/reactbg.png") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100%;
}

body {
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: transparent;
  font-family: Consolas, Calibri;
  min-height: 100%;
  height: 100%;
  padding-top: 170px;
  color: white;
}

.container {
  min-height: 100%;
  height: 100%;
  position: relative;
}

.container-fluid {
  position: relative;
}

/* Set padding to keep content from hitting the edges */
.body-content {
  padding-left: 15px;
  padding-right: 15px;
  background-color: transparent;
}

/* Override the default bootstrap behavior where horizontal description lists
 will truncate terms that are too long to fit in the left column
*/
.dl-horizontal dt {
  white-space: normal;
}

/* Set width on the form input elements since they're 100% wide by default */
input,
select,
textarea {
  max-width: 280px;
}

.small-me {
  height: 140px;
  cursor: pointer;
}

.rounded-me {
  border-radius: 180px;
  border: 3px solid white;
}

.navbar {
  border-bottom: 4px solid white;
  background-color: black !important;
  color: #119eb4 !important;
}

.font-size-small {
  font-size: 24pt;
}

.body-content {
  margin-top: 15px;
}

.navbar a {
  text-decoration: none;
  color: #119eb4 !important;
}

div.body-content .list-group a,
div.body-content .list-group a:hover {
  color: black !important;
  text-decoration: none;
}

div.body-content a:hover {
  text-decoration: none;
}

div.body-content a {
  color: yellow !important;
  text-decoration: underline;
}

.myListGroup a {
  color: black !important;
  text-decoration: none;
}

.nav-link {
  border-bottom: 1px solid white !important;
}

.active {
  border-bottom: 1px solid #119eb4 !important;
}

.nav-item .active a {
  font-weight: bold;
}

.linkedin-blue {
  color: #0a66c2;
}

.signature {
  margin-top: 50px;
  margin-bottom: 50px;
}

.transform {
  transform: rotate(-2deg);
  width: 100%;
}

input,
select,
textarea {
  width: 100%;
  min-width: 100%;
}

/*When mobile phone!*/
@media screen and (max-width: 768px) {
  div.reactLogo {
    display: none;
  }
  .navbar-brand {
    zoom: 0.6;
  }

  body {
    padding-top: 100px;
    height: 100vh;
  }

  html {
    height: 100vh;
  }

  .body-content {
    margin-top: 25px;
  }

  .logoutLink {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 260px !important;
  }
}
